//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CajaServices from "../../Services/CajaServices";
import CajasHelper  from "./CajasHelper";

const cajaServices = new CajaServices();
export default {
  name: "cajaMostrador",
  components: {
    FacturaModal: () => import("../facturacion/modales/facturaModal"),
    VentaDetalle: () => import("./modales/ventaDetalle"),
    BuscarVentasModal: () => import("./modales/buscarVentasModal")
  },
  data() {
    return {
      ventaObj: {},
      ventaId: this.$route.params.id,
      buscarVentasModal: false,
      pagoTab: "efectivo",
      pagos: [],
      eliminarPagoModal: false,
      pagoObj: {},
      indexSeleccionado: "",
      formasPago: [],
      tienda: {},
      cuentaBancariaDefault: "",
      permitirPagosSinOperacionCaja: false,
      operacionCajaId: localStorage.getItem("operacionCajaId"),
      cuentasBancarias: [],
      cuentaBancariaSelccionada: "",
      monto: "",
      formaPagoSeleccionado: "",
      numeroAutorizacion: "",
      ventaInfo: {},
      ventaSaldoCambioModal: false,
      configVentas: {},
      cajaObj: {},
      confirmacionModal: false,
      ventaDetalleModal: false,
      tipoVentaDetalle: "",
      facturarVentaModal: false,
    }
  },
  computed: {
    totalPagosRecibidos() {
      return _.sumBy(this.pagos, p => Number(p.monto));
    },
    cambio() {
      let cambio = 0;
      if (this.totalPagosRecibidos > this.ventaObj.saldo)
        cambio = Number(this.totalPagosRecibidos) - Number(this.ventaObj.saldo || 0);
      return cambio;
    },
    isVentaPagada() {
      return this.ventaObj.totalPagado === this.ventaObj.totalConIva || this.ventaObj.saldo === 0;
    },
    permitirFacturar() {
      let habilitado = false;
      if (_.isEmpty(this.ventaObj.tipoVenta)) return false;

      if (Number(this.ventaObj.tipoVenta.codigo) === 100) {
        if (Number(this.ventaObj.totalPagado) < Number(this.ventaObj.totalConIva)) {
          habilitado = false;
        } else if (Number(this.ventaObj.totalPagado) >= Number(this.ventaObj.totalConIva)) {
          habilitado = true;
        }
      } else if (Number(this.ventaObj.tipoVenta.codigo) === 101) {
        habilitado = true;
      }
      return Number(this.configVentas['permitir-facturar-caja']) === 1 && habilitado && !this.ventaObj.facturaId;
    }
  },
  watch: {
    pagoTab() {
      this.$nextTick(() => this.$refs.first.focus());
      this.limpiarPago();
      if (this.pagoTab === "bancario" && this.cuentasBancarias.length === 1)
        this.cuentaBancariaSelccionada = this.cuentasBancarias[0];
    },
  },
  created() {
    window.addEventListener('keyup', this.fireHotKeys)
  },
  async mounted() {
    this.loader(true);
    await this.cargarDatosIniciales();
    this.loader(false);
  },
  methods: {
    fireHotKeys(e) {
      if (e.which === 119 && this.pagos.length > 0)
        this.confirmacionModal = true;

      if (e.which === 113 && !_.isEmpty(this.ventaObj))
        this.$refs.first.focus();
    },
    isEmpty: _.isEmpty,
    cargarDatosIniciales() {
      let tienda = JSON.parse(localStorage.getItem("tiendaDetalle"));

      return this.obtenerDatosCajaMostradorInicial()
        .then(() => {
          if (!this.cajaObj.cobro_lotes_series && !_.isEmpty(this.ventaObj) && [100, 400].includes(Number(this.ventaObj.entregaProductos))) {
            this.alertShow("No se pueden realizar pagos a esta venta porque cuenta con salida de lotes y series pendiente", "error");
            return this.limpiarCaja();
          }
          let tiendaMetadatos = JSON.parse(tienda.metadatos),
              caja = JSON.parse(localStorage.getItem("cajaObj"));

          this.formasPago                    = JSON.parse(localStorage.getItem("formasPago"));
          this.configVentas                  = JSON.parse(localStorage.getItem("configVentas"));
          this.tienda                        = tienda;
          this.cuentaBancariaDefault         = tienda.cuenta_default_efectivo_id;
          this.cajaObj                       = caja;
          this.permitirPagosSinOperacionCaja = !_.isEmpty(tiendaMetadatos) && Number(tiendaMetadatos.permitir_pago_sin_operacion_caja) === 1;
          this.pagos                         = [];
          this.limpiarPago();
          if (!_.isEmpty(this.ventaObj)) this.$nextTick(() => this.$refs.first.focus());
        })
        .catch(error => this.alertShow(error, "error"));
    },
    async obtenerDatosCajaMostradorInicial() {
      let filtros  = {
        ventaId: this.ventaId,
        status: "200",
        orden: "NOMBRE_CUENTA_AZ",
        tipo: 100
      };
      let response = await cajaServices.obtenerDatosCajaMostradorInicial(filtros);

      if (!_.isObject(response)) throw new Error(response);

      this.cuentasBancarias = response.cuentasBancarias;
      if (!_.isEmpty(response.ventaDetalle)) {
        this.ventaObj  = response.ventaDetalle.venta;
        this.ventaInfo = response.ventaDetalle;
      }
      return Promise.resolve();
    },
    async obtenerVenta(ventaId) {
      this.loader(true);
      this.ventaId = ventaId;
      await this.obtenerDatosCajaMostradorInicial();
      this.pagos = [];
      this.buscarVentasModal = false;
      this.loader(false);
      if (!this.cajaObj.cobro_lotes_series && [100,400].includes(Number(this.ventaObj.entregaProductos))) {
        this.alertShow("No se pueden realizar pagos a esta venta porque cuenta con salida de lotes y series pendiente", "error");
        return this.limpiarCaja();
      }
      this.$nextTick(() => this.$refs.first.focus());
    },
    aplicarPago() {
      if (this.totalPagosRecibidos >= this.ventaObj.saldo)
        return this.alertShow("Ya se ha alcanzado el monto saldo de la venta. Ya no se pueden registrar más pagos", "error");

      let cuentaTienda     = _.find(this.cuentasBancarias, {cuentaBancariaId: this.tienda.cuenta_default_efectivo_id});
      let cuentaDefault    = !_.isEmpty(cuentaTienda) ? cuentaTienda : {cuentaBancariaId: "", cuentaBancariaNombre: ""};
      let formaPagoDefault = this.formasPago[0];

      let formaPagoClave       = CajasHelper.obtenerClaveFormaPago(this.pagoTab === "efectivo", formaPagoDefault, this.formaPagoSeleccionado);
      let formaPagoDescripcion = this.pagoTab === "efectivo" ? formaPagoDefault.descripcion : this.formaPagoSeleccionado.descripcion;
      let cuentaBancariaId     = this.pagoTab === "efectivo" ? cuentaDefault.cuentaBancariaId : this.cuentaBancariaSelccionada.cuentaBancariaId;
      let cuentaNombre         = this.pagoTab === "efectivo" ? cuentaDefault.cuentaBancariaNombre : this.cuentaBancariaSelccionada.cuentaBancariaNombre;

      let pago = {
        formaPagoClave,
        formaPagoDescripcion,
        monto: this.monto,
        numeroAutorizacion: this.numeroAutorizacion,
        cuentaNombre,
        cuentaBancariaId,
        fecha: this.moment().format('YYYY-MM-DD H:mm:ss'),
        autor: localStorage.getItem("nombre"),
      };
      this.pagos.push(pago);
      this.limpiarPago();
      this.$nextTick(() => this.$refs.first.focus());
    },
    limpiarPago() {
      this.monto                     = "";
      this.formaPagoSeleccionado     = "";
      this.numeroAutorizacion        = "";
      this.cuentaBancariaSelccionada = "";
    },
    cargarPagoEliminar(pago, index) {
      this.pagoObj           = _.clone(pago);
      this.indexSeleccionado = index;
      this.eliminarPagoModal = true;
    },
    eliminarPago(index) {
      let array = _.cloneDeep(this.pagos);
      array.splice(index, 1);
      this.pagos             = array;
      this.eliminarPagoModal = false;
      this.limpiarPagoSeleccionado();
    },
    limpiarPagoSeleccionado() {
      this.pagoObj           = {};
      this.indexSeleccionado = "";
    },
    registrarPagosVenta() {
      if (!this.permitirPagosSinOperacionCaja && _.isEmpty(this.cajaObj.operacionCajaId))
        return this.alertShow("La tienda no permite pagos sin una operación de caja iniciada.", "error");

      if (!this.permisos.existePermiso(this.permisos.PERMISO_VENTAS_AGREGAR_PAGO))
        return this.alertShow("No cuenta con los permisos necesarios para realizar esta acción(" + this.permisos.PERMISO_VENTAS_AGREGAR_PAGO + ")");

      let data = {
        observaciones: "",
        ventaId: this.ventaId,
        saldo: this.ventaObj.saldo,
        operacionCajaId: this.operacionCajaId,
        pagos: JSON.stringify(CajasHelper.obtenerMapPagos(this.pagos, this.ventaObj.saldo)),
      };
      this.loader(true);
      return cajaServices.registrarPagos(data)
        .then(async resp => {
          if (resp.codigo === 410)
            this.ventaSaldoCambioModal = true;
          else {
            this.alertShow(resp.mensaje, "exito");
            await this.cargarDatosIniciales();
          }
          this.loader(false);
          this.confirmacionModal = false;
        }).catch(error => {
          this.loader(false);
          this.alertShow(error, "error");
        });
    },
    obtenerPDFVenta() {
      this.loader(true);
      let filtros = {ventaId: this.ventaObj.ventaId};

      return cajaServices.getPdfVenta(filtros)
        .then(res => {
          let linkSource   = `data:application/pdf;base64,${res.pdf}`;
          let downloadLink = document.createElement("a");
          let fileName     = res.nombre;

          downloadLink.href     = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.alertShow("PDF de venta descargado correctamente.", "exito");
        }).catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    limpiarCaja() {
      this.ventaId   = "";
      this.ventaObj  = {};
      this.ventaInfo = "";
      this.actualizarVenta();
    },
    async actualizarVenta() {
      this.loader(true);
      await this.cargarDatosIniciales();
      this.ventaSaldoCambioModal = false;
      this.loader(false);
      if (!_.isEmpty(this.ventaObj)) this.$nextTick(() => this.$refs.first.focus());
    },
    abrirDetalleVenta(tipo = 'completa') {
      if (_.isEmpty(this.ventaObj)) return;
      this.tipoVentaDetalle  = tipo;
      this.ventaDetalleModal = true;
    },
    async cerrarFacturaModal() {
      await this.obtenerVenta(this.ventaId);
      this.facturarVentaModal = false;
    },
  }
}
