import moment    from "moment"
import {Decimal} from "decimal.js";

export default {
  obtenerMapPagos(pagos, saldo) {
    let acumulado = 0;
    return pagos.map(function (pago) {
      let pagoAcumulado = Number(Number(pago.monto) + acumulado),
          monto = pagoAcumulado > Number(saldo) ? Number(acumulado - saldo) : Number(pago.monto);
      let obj = {
        autorId: localStorage.getItem("usuarioId"),
        monto: new Decimal(monto).abs().toNumber().toFixed(2),
        claveFormaPago: pago.formaPagoClave,
        nombreFormaPago: pago.formaPagoDescripcion,
        fechaRegistroTimestamp: moment(pago.fecha).toDate().getTime(),
        cuentaBancariaId: pago.cuentaBancariaId,
        numeroAutorizacion: pago.numeroAutorizacion
      };
      acumulado = Number(acumulado) + Number(monto);
      return obj;
    });
  },
  obtenerClaveFormaPago(valido, opcion1, opcion2) {
    let opcionValida = valido ? opcion1 : opcion2;

    return opcionValida.clave || opcionValida.c_forma_pago;
  }
}